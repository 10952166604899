@use "sass:meta" as ---6r6i8kqj18t;.errorlist {
  display: flex;
  flex-flow: column;
  margin: -$global-margin 0 $global-margin;

  li {
    color: $alert-color;
    display: block;
    list-style-type: none;
    padding: $input-padding;
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3860");