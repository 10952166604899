@use "sass:meta" as ---6r6i8kqj18t;.help-parts {
  .part {
    border-bottom: 1px solid $medium-gray;
    padding: $global-padding 0;

    a {
      font-size: rem-calc(20);
      font-weight: $global-weight-bolder;
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3879");