@use "sass:meta" as ---6r6i8kqj18t;.offer-tizer-block {
  @include breakpoint (medium only) {
    .row > .column {
      &:nth-child(n+9) {
        display: none;
      }
    }
  }

  .item {
    border-radius: $global-radius;
    border: solid 1px $alice-blue;
    padding: 10px;
    transition: .2s;

    &:hover {
      $color: color-pick-contrast($alice-blue, ($button-color, $button-color-alt));
      $background-hover: scale-color($alice-blue, $lightness: $button-background-hover-lightness);

      border-color: $background-hover;

      .order__button {
        background-color: $background-hover;
      }
    }

    .picture {
      align-items: center;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      width: 100%;
    }

    .title {
      font-size: rem-calc(14);
      line-height: 18px;
      height: 18px * 3;
      display: -webkit-box; /* stylelint-disable-line */
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1rem;
    }

    .vendor-code {
      font-size: 11px;
      margin-bottom: .5rem;
      color: map_get($foundation-palette, secondary);
      display: -webkit-box; /* stylelint-disable-line */
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .price {
      display: flex;
      align-items: flex-end;
      font-weight: $global-weight-bolder;
      margin-bottom: .5rem;

      span {
        padding-top: 0;
        font-weight: $global-weight-normal;
      }
    }

    .seller {
      font-size: rem-calc(13);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;

      @include breakpoint(small only) {
        max-width: 140px;
      }

      @include breakpoint(medium) {
        max-width: 190px;
      }
    }

    .order {
      margin-top: 1rem;

      &__button {
        @include button($expand: true, $background: $alice-blue, $background-hover: auto, $color: $black);

        margin-bottom: 0;
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3883");