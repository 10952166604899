@use "sass:meta" as ---6r6i8kqj18t;.partner-list {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__logo {
    line-height: 0;
    margin: 1rem;
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3835");