@use "sass:meta" as ---6r6i8kqj18t;@use 'sass:math';

.obj-8 {
  @include grid-column-margin;

  border-radius: $global-radius;
  border: 1px solid $medium-gray;
  flex-direction: column;
  min-height: 12rem;
  padding: $global-padding;
  position: relative;

  &:hover {
    border-color: $primary-color;
  }

  .button-group {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__wrap {
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }

  &__header {
    display: flex;

    img {
      vertical-align: top;
    }
  }

  &__contacts {
    margin-top: $global-margin;
  }

  &__title {
    margin: 0 0 math.div($global-margin, 2) 0;
    font-size: rem-calc(15);
    font-weight: $global-weight-bold;
  }

  &__mail {
    position: absolute;
    right: $global-margin;
    top: $global-margin;
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3832");