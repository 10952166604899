@use "sass:meta" as ---6r6i8kqj18t;.icon {
  &-info,
  &-stars-1,
  &-status-2 {
    background-repeat: no-repeat;
    background-image: url('svg/stars-1-inline.svg');
  }

  &-promo,
  &-stars-2,
  &-status-3 {
    background-repeat: no-repeat;
    background-image: url('svg/stars-2-inline.svg');
  }

  &-business,
  &-stars-3,
  &-status-4 {
    background-repeat: no-repeat;
    background-image: url('svg/stars-3-inline.svg');
  }

  &-vip,
  &-status-5 {
    background-repeat: no-repeat;
    background-image: url('svg/vip-inline.svg');
  }

  &-contact {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;

    @include breakpoint (small only) {
      width: 40px;
      height: 40px;
    }

    &-avito {
      background-image: url('svg/contact-avito.svg');

      &:hover {
        background-image: url('svg/contact-avito-hover.svg');
      }
    }

    &-dzen {
      background-image: url('svg/contact-dzen-inline.svg');

      &:hover {
        background-image: url('svg/contact-dzen-hover-inline.svg');
      }
    }

    &-facebook {
      background-image: url('svg/contact-facebook.svg');

      &:hover {
        background-image: url('svg/contact-facebook-hover.svg');
      }
    }

    &-instagram {
      background-image: url('svg/contact-instagram.svg');

      &:hover {
        background-image: url('svg/contact-instagram-hover.svg');
      }
    }

    &-linkedin {
      background-image: url('svg/contact-linkedin.svg');

      &:hover {
        background-image: url('svg/contact-linkedin-hover.svg');
      }
    }

    &-livejournal {
      background-image: url('svg/contact-livejournal.svg');

      &:hover {
        background-image: url('svg/contact-livejournal-hover.svg');
      }
    }

    &-yandex-market {
      background-image: url('svg/contact-yandex-market.svg');

      &:hover {
        background-image: url('svg/contact-yandex-market-hover.svg');
      }
    }

    &-ok {
      background-image: url('svg/contact-ok-inline.svg');

      &:hover {
        background-image: url('svg/contact-ok-hover-inline.svg');
      }
    }

    &-ozon {
      background-image: url('svg/contact-ozon.svg');

      &:hover {
        background-image: url('svg/contact-ozon-hover.svg');
      }
    }

    &-pinterest {
      background-image: url('svg/contact-pinterest-inline.svg');

      &:hover {
        background-image: url('svg/contact-pinterest-hover-inline.svg');
      }
    }

    &-rutube {
      background-image: url('svg/contact-rutube.svg');

      &:hover {
        background-image: url('svg/contact-rutube-hover.svg');
      }
    }

    &-telegram {
      background-image: url('svg/contact-telegram-inline.svg');

      &:hover {
        background-image: url('svg/contact-telegram-hover-inline.svg');
      }
    }

    &-tiktok {
      background-image: url('svg/contact-tiktok.svg');

      &:hover {
        background-image: url('svg/contact-tiktok-hover.svg');
      }
    }

    &-tumblr {
      background-image: url('svg/contact-tumblr.svg');

      &:hover {
        background-image: url('svg/contact-tumblr-hover.svg');
      }
    }

    &-twitter {
      background-image: url('svg/contact-twitter.svg');

      &:hover {
        background-image: url('svg/contact-twitter-hover.svg');
      }
    }

    &-vk {
      background-image: url('svg/contact-vk-inline.svg');

      &:hover {
        background-image: url('svg/contact-vk-hover-inline.svg');
      }
    }

    &-wildberries {
      background-image: url('svg/contact-wildberries.svg');

      &:hover {
        background-image: url('svg/contact-wildberries-hover.svg');
      }
    }

    &-youtube {
      background-image: url('svg/contact-youtube-inline.svg');

      &:hover {
        background-image: url('svg/contact-youtube-hover-inline.svg');
      }
    }

    &-whatsapp {
      background-image: url('svg/contact-whatsapp-inline.svg');

      &:hover {
        background-image: url('svg/contact-whatsapp-hover-inline.svg');
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3871");