@use "sass:meta" as ---6r6i8kqj18t;.arr {
  cursor: pointer;
  display: block;
  font-size: rem-calc(14);
  position: relative;

  &::before {
    color: $dark-gray;
    font-size: rem-calc(12);
    position: absolute;
    width: 1rem;
  }

  &_prev {
    padding-left: $global-padding * 2;
    text-align: left;

    &::before {
      content: '\2190';
      left: .5rem;
    }
  }

  &_next {
    padding-right: $global-padding * 2;
    text-align: right;

    &::before {
      content: '\2192';
      right: .5rem;
    }
  }

  &_contents {
    padding-left: $global-padding * 2;
    padding-right: $global-padding * 2;
    text-align: center;
  }

  &_ellipse {
    border-radius: $global-radius;
    border: 1px solid $medium-gray;
    color: $black;
    display: inline-block;
    padding-bottom: .5rem;
    padding-top: .5rem;

    &:hover {
      color: $primary-color;
    }

    &.arr_next {
      padding-left: $global-padding;
      padding-right: $global-padding * 2;

      &::before {
        right: rem-calc(12);
      }
    }

    &.arr_prev {
      padding-left: $global-padding * 2;
      padding-right: $global-padding;

      &::before {
        left: rem-calc(12);
      }
    }

    &::before {
      color: $black;

      //width: 20px;
      font-size: rem-calc(15);
      top: 6px;
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3807");