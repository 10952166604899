@use "sass:meta" as ---6r6i8kqj18t;.person {
  &-list {
    @include grid-column-margin;

    h3 {
      font-weight: $global-weight-bolder;
    }

    &__fio {
      display: block;
      font-weight: $global-weight-bold;
      margin-bottom: .3rem;
      overflow: hidden;
      text-align: center;
    }

    &__company {
      color: $dark-gray;
      font-size: rem-calc(12);
      overflow: hidden;
      text-align: center;
    }
  }

  &-card {
    .card-section {
      h4 {
        font-size: $global-font-size;
      }

      p {
        line-height: 1.2;
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3836");