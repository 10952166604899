@import 'assets/styles/core';

.subscribe-magazine {
  margin: rem-calc(20 0 30);
  background-color: #f6f6f6;
  padding: rem-calc(20);
  border-radius: $global-radius;

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    padding: rem-calc(20 30);
  }

  .wrapper {
    flex-basis: 100%;
  }

  .intro {
    color: #5072af;
  }

  .subtitle {
    @include hide-for-only(small);

    @include breakpoint(medium only) {
      font-size: rem-calc(14);
    }
  }

  .title {
    font-size: rem-calc(20);
    line-height: 1.4;
    font-weight: $global-weight-bold;

    @include breakpoint(medium) {
      font-size: rem-calc(24);
    }
  }

  .message {
    font-size: rem-calc(18);
    line-height: rem-calc(36);
    margin: rem-calc(20 0 0);
  }

  form {
    margin: rem-calc(20 0 0);

    @include breakpoint(medium) {
      display: flex;
      align-items: flex-end;
    }

    input {
      height: rem-calc(36);
      margin-bottom: 0;
      padding: rem-calc(10);
    }

    input[type='email'] {
      @include breakpoint(small only) {
        margin: rem-calc(20 0);
      }
    }

    button {
      margin-bottom: 0;

      @include breakpoint(small only) {
        width: 100%;
      }

      @include breakpoint(medium) {
        padding: rem-calc(0 20);
        height: rem-calc(36);
      }
    }
  }

  label {
    margin-right: rem-calc(20);
    width: 100%;
  }

  .cover {
    @include hide-for-only(small);

    @include breakpoint(medium) {
      width: 100px;
      line-height: 1;
      flex-shrink: 0;
      margin-left: rem-calc(35);
    }

    @include breakpoint(large) {
      width: 120px;
    }
  }
}
