@use "sass:meta" as ---6r6i8kqj18t;.search-grid {
  @include grid-column-margin;

  background-color: #f3f3f3;
  padding: 20px;

  .input-group {
    margin-bottom: 0;
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:3843");